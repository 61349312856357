import { useCallback } from "react";
import "./LeftPage.css";

export const Link = ({ data }) => {
  const clickToLink = useCallback(() => {
    if (data.link) {
      window.open(data.link, "_blank");
    }
  }, [data.link]);

  return (
    <span className="link" onClick={clickToLink}>
      {data.text}
    </span>
  );
};
