import "./RightPage.css";
import right from "../assets/Right.png";
import shadow_right_one from "../assets/shadow_right_one.png";
import { useRef, useState } from "react";

export const RightPage = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  const handlePlay = () => {
    const video = videoRef.current;
    if (video) {
      video.muted = !isMuted;
      setIsMuted(!isMuted);
      video.play().catch((error) => {
        console.error("Ошибка воспроизведения:", error);
      });
    }
  };

  return (
    <div className="right">
      <img
        src={right}
        alt="right"
        draggable={false}
        style={{
          position: "absolute",
          top: "15px",
          left: "-30px",
          width: "709.24px",
          height: "1043px",
        }}
      />
      <img
        src={shadow_right_one}
        alt="shadow_right_one"
        draggable={false}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "720px",
          height: "1080px",
          zIndex: 3,
        }}
      />
      <span className="right_title">Piggy Smallz</span>
      <div className="right_block" />
      <div className="right_video" onClick={handlePlay}>
        <video
          ref={videoRef}
          src="/ariana.mp4" // Укажите путь к вашему видео
          controls={false}
          autoPlay
          loop
          muted
          playsInline
          style={{ width: "600px", height: "250px" }} // Устанавливаем размеры видео
        />
      </div>
    </div>
  );
};
