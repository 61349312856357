import "./App.css";

import { LeftPage } from "./Left/LeftPage";
import { RightPage } from "./Right/RightPage";

import noise from "./assets/noise.png";

function App() {
  return (
    <div className="main">
      <img
        src={noise}
        alt="noise"
        draggable={false}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 2,
        }}
      />
      <LeftPage />
      <RightPage />
    </div>
  );
}

export default App;
