import "./LeftPage.css";
import { Link } from "./Link";

import pig_one from "../assets/pig_one.png";
import paper from "../assets/paper.png";
import circle from "../assets/circle.png";
import pen from "../assets/Pen.png";
import ariana from "../assets/Ariana.png";
import shadow_left_one from "../assets/shadow_left_one.png";

const links = [
  {
    text: "Telegram",
    link: "https://t.me/PiggySmallzSol",
  },
  {
    text: "Twitter (X)",
    link: "https://x.com/PiggySmallzSol",
  },
  {
    text: "Instagram",
    link: "https://www.instagram.com/realpiggysmallz",
  },
];

const handleCopy = () => {
  const contract = "Edmgh881qN8ecXd42cbHDk2sPnSDWPzNwUv67NZbpump";
  navigator.clipboard.writeText(contract);
};

export const LeftPage = () => {
  return (
    <div className="left">
      <img src={pen} alt="pen" draggable={false} className="left_pen" />
      <img
        src={ariana}
        alt="ariana"
        draggable={false}
        className="left_ariana"
      />
      <span className="left_title">ARIANA'S PIGGY SMALLZ</span>
      <span className="left_data">01 November, 2024</span>
      <img
        className="left_pig_one"
        src={pig_one}
        alt="pig_one"
        draggable={false}
      />
      <span className="left_text" style={{ marginTop: "20px" }}>
        Ariana kept Piggy Smallz following her split from Pete. Ariana created
        an Instagram account for Piggy, @realpiggysmallz. It currently has
        over 321K followers but is inactive since January 2019. Ariana made
        an unofficial music video for "Breathin" featuring Piggy Smallz.
      </span>
      <img className="left_paper" src={paper} alt="paper" raggable={false} />
      <div className="left_content">
        <img
          src={circle}
          alt="circle"
          draggable={false}
          className="left_circle"
        />
        <span className="left_title_two">ARIANA'S PIGGY SMALLZ</span>
        <button className="button" onClick={handleCopy}>
          Coppy CA
        </button>
        <div className="left_buttons">
          {links.map((link, index) => (
            <Link data={link} key={index} />
          ))}
        </div>
      </div>
      <span className="left_text_bottom">
        During the 34+35 Remix (Countdown to Premiere), @bloodsline on
        Twitter asked about Piggy Smallz, and Grande jokingly stated that
        Piggy had asked her to stop posting her as much because "she is
        actually really offended by the jokes you make on Twitter," and that
        "she doesn't like the jokes about her being eaten," but then
        continued with "No, she's great!".
      </span>
      <span className="left_pageN">01</span>

      <img
        src={shadow_left_one}
        alt="shadow_left_one"
        draggable={false}
        style={{
          position: "absolute",
          right: 0,
          marginLeft: 0,
          zIndex: 3,
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};
